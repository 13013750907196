import React, { useEffect, useState } from 'react';
import { Layout, Card, Typography, Button } from 'antd';
import {Col, TextInput, Icon, Select, Checkbox, Textarea, Autocomplete} from "react-materialize";
import { useHistory } from "react-router-dom";
import { convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { useSelector } from 'react-redux';
import styles from './NewVideoRequestStyle.module.scss';
import TopHeader from '../TopHeader/TopHeader';
import { errorToast, infoToast, isAdmin, resize, successToast, uploadMedia } from "../../api/Util";
import BackArrowIcon from '../../components/img/back_arrow.png';
import VoiceRecorder from "../VoiceRecorder";
import { axiosClient } from '../../api/httpClient';
import './NewVideoRequestStyle.scss';
import { CUSTOMER } from '../../api/Constants';


const INITIAL_STATE = {
    client_id: null,
    card_title: "",
    dropbox_link: "",
    youtube_aspect_ratio: "",
    video_purpose: "",
    additional_info: "",
    special_instructions: "",
    video_thumb_desc: "",
    video_thumb_ref: [""],
    video_optimization_desc: "",
    affiliate_links: [""],
    subtitles: false,
    youtube_helper: false,
    repurpose_additional_info: "",
    call_to_action: "",
};

const NewVideoRequest = () => {
    const [repurpose, setRepurpose] = useState(false);
    const [repurposeRatios, setRepurposeRatios] = useState(0);
    const [repurposeSq, setRepurposeSq] = useState(false);
    const [repurpose916, setRepurpose916] = useState(false);
    const [repurpose45, setRepurpose45] = useState(false);
    const [repurposeOther, setRepurposeOther] = useState(false);
    const [repurposeAspect, setRepurposeAspect] = useState("");
    const [headlineRepurpose, setHeadlineRepurpose] = useState("");
    const [CTARepurposeCheck, setCTARepurposeCheck] = useState(false);
    const [otherAspectRatio, setOtherAspectRatio] = useState("");
    const [state, setState] = useState(INITIAL_STATE);
    const [error, setError] = useState(false);
    const [voiceBlob, setVoiceBlob] = useState(new Blob([]));
	  const websiteUsers = useSelector(state => state.allWebsiteUsers.website_users);
    const props = useSelector((state) => state)
    const loggedInUser = props.auth?.loggedInUser;

    let history = useHistory();

    function onChange(e, index) {
        let name = e.target.name;
        let value = e.target.value;
        if(name === 'card_title') {
            setError(false);
            document.getElementById("card-title-error-message").style.display = "none";
        }

        
        if(e.target.type === 'checkbox') {
            setState(prevState => ({...prevState, [name]: !prevState[name]}));
        } else if(index != null && index >= 0) {
            let list = state[name];
            list = resize(list, index, "");
            list[index] = value;
            setState(prevState => ({...prevState, [name]: list}));
        } else {
            setState(prevState => ({...prevState, [name]: value}));
        }
    }

    function handleEditorStateChange(e,sectionName){
        if(e) {
            // Converting editor state to html 
            let value = draftToHtml(convertToRaw(e.getCurrentContent()));
            // Storing the editor state to display in the model 
            setState(prevState => ({...prevState, [sectionName+"1"]: e}));
            // Storing the editor html tag in state so that it can be pass to backend
            setState(prevState => ({...prevState, [sectionName]: value}));
        }
    }

    function onChangeRepurposeAspect(val, fn) {
        if(val) {
            fn(!val);
            setRepurposeRatios(repurposeRatios - 1);
        } else if(repurposeRatios < 2) {
            fn(!val);
            setRepurposeRatios(repurposeRatios + 1);
        }
    }

    function validateState() {
        const repurposeConditions = [repurposeSq, repurpose916, repurpose45, repurposeOther];
        const emptyRepurposeConditions = repurposeConditions.filter(condition => condition).length;
        return repurpose ?
            (state.card_title !== "" && state.dropbox_link !== "" && state.youtube_aspect_ratio !== "" && state.video_purpose !== "" && emptyRepurposeConditions >= 1) :
            (state.card_title !== "" && state.dropbox_link !== "" && state.youtube_aspect_ratio !== "" && state.video_purpose !== "");
    }
    
    function makeRepurposeRatioText() {
        let ratio = "";
        if(repurposeSq) {
            ratio += "1:1 Square Video,";
        }
        if(repurpose916) {
            ratio += "9:16 IGTV Vertical Video,";
        }
        if(repurpose45) {
            ratio += "4:5 In Feed Vertical Video,";
        }
        if(repurposeOther) {
            ratio += repurposeAspect;
        }
        return ratio;
    }

    useEffect(() => {
        setState(prevState => ({...prevState, client_id: loggedInUser.client_id}));
    }, [loggedInUser.client_id]);

    async function createCard() {

        // to convert card name like ABC to Abc 
        let new_card_title = state.card_title;
        if(state.card_title === state.card_title.toUpperCase())
        {
            new_card_title = new_card_title.slice(0,1) + new_card_title.slice(1).toLowerCase();
        }
        if (state.youtube_aspect_ratio === "Other") {
            state.youtube_aspect_ratio = otherAspectRatio;
        }


        // deleting the extra field which do not needed anymore 
        delete state.additional_info1;
        delete state.special_instructions1;
        delete state.video_thumb_desc1;
        delete state.video_optimization_desc1;

        infoToast("Creating new request");
        let voiceNote = await uploadMedia(voiceBlob, loggedInUser.client_id);

        let {youtube_helper,card_title, ...body} = state;
        let repurposeRatio = makeRepurposeRatioText();
        axiosClient.post("/api/card", { ...body,card_title:new_card_title, voice_note: voiceNote, repurpose_aspect: repurposeRatio,
            repurpose_headline: headlineRepurpose, hasYTHelper: state.youtube_helper ? state.youtube_helper : false, hasRepurpose: repurpose })
            .then(() => {
                successToast("New request created");
                setState(INITIAL_STATE);
                history.goBack();
            }).catch(err => {
                errorToast(err?.response?.data?.message);
                if (err?.response?.data?.message === "Card Title already exist") {
                    document.getElementById("card-title-error-message").style.display = "block";
                    document.getElementById("card-title-error-message").style.marginBottom = "1rem";
                }
                setError(true)
                console.error(err);
            });
    }

    const hideComponent = (callFrom) => {
        if (callFrom === 'div1') {
            document.getElementById('div1').style.display = 'none';
            document.getElementById('div2').style.display = 'block';
        } else {
            document.getElementById('div2').style.display = 'none';
            document.getElementById('div1').style.display = 'block';
        }
    }

	function makeSelectCustomer() {
		const autocompleteOptions = (websiteUsers || [])
			.filter((customer) => customer.client_type === CUSTOMER && !customer.is_deleted)
			.reduce((options, customer) => {
				options[customer.fullname] = null; // you can set an icon or image URL instead of null if needed
				return options;
			}, {});

		return [
			<Col s={12} key="label-autocomplete" className="label-column">
				<label>Request for</label>
			</Col>,
			<Autocomplete
				s={12}
				id="create-for-customer"
				options={{
					data: {
						Me: null,
						...autocompleteOptions
					},
					onAutocomplete: (value) => {
						const selectedCustomer = websiteUsers.find(customer => customer.fullname === value) || loggedInUser;
						onChange({ target: { name: "client_id", value: selectedCustomer.client_id } });
					}
				}}
				icon={<Icon>person</Icon>}
				placeholder="Search Customer"
				key="autocomplete"
			/>
		];
	}

    return (
        <Layout className={styles.Layout}>
            <Card className={styles.topHeader} loading={false}>
            <TopHeader />
            </Card>
            <div className={styles.container}>
                <div className={styles.titleAndIconWrapper}>
                    <Button type="link" className={`${styles.greyFontColor} ${styles.arrowIconAndTextWrapper}`} onClick={() => history.goBack()}>
                        <img src={BackArrowIcon} alt="BackArrowIcon" /> Back
                    </Button>
                    <Typography.Title className={styles.cardTitle}>
                        New Video Request
                    </Typography.Title>

                </div>
                <div className={`${styles.fieldsWrapper} selectInput`} id="div1">
                    {isAdmin(loggedInUser) ?
                        makeSelectCustomer()
                        : ""
                    }
                    <Col s={12} className="label-column">
                        <label>What title should we use for this video? *</label>
                    </Col>
                    <Col s={12}>
                        <TextInput id="txt_card_title" className= {error ? "error validate" : "validate"} icon="title" name="card_title" placeholder="Title" onChange={onChange} value={state.card_title}/>
                        <span id="card-title-error-message" style={{ color: '#ba3939',display:'none'}} >This title already exist. Please try different title or use versions like Title A or Title B.</span> 
                    </Col>
                    
                    <Col s={12} className="label-column">
                        {/* Change from "What is the Dropbox folder download link for raw video? *" */}
                        <label>What is the Gdrive/Dropbox folder download link for raw video? *</label>
                    </Col>
                    <TextInput id="txt_dbx_link" s={12} icon="link" name="dropbox_link" type="url" validate placeholder="Gdrive/Dropbox Link" onChange={onChange} value={state.dropbox_link}/>

                    <Col s={12} className="label-column">
                        <label>Which format do you want your video in? *</label>
                    </Col>
                    <Select s={12} icon={<Icon>aspect_ratio</Icon>} id="aspect_ratio" name="youtube_aspect_ratio" onChange={onChange} value={state.youtube_aspect_ratio}>
                        <option value="">{/**/}</option>
                        <option value="Horizontal (16:9)">Horizontal 16:9</option>
                        <option value="Vertical (9:16)">Vertical 9:16 (most common)</option>
                        <option value="In Feed 4:5">In Feed 4:5</option>
                        <option value="Square (1:1)">Square (1:1)</option>
                        <option value="Other">Other</option>
                    </Select>,
                    {state.youtube_aspect_ratio === "Other" ? (
                            <TextInput
                                placeholder="Custom Aspect Ratio"
                                value={otherAspectRatio}
                                onChange={e => setOtherAspectRatio(e.target.value)}
                                style={{  margin:"-20px 0 0 20px" }}
                            />
                    ) : null}
                    <Col s={12} className="label-column">
                        <label>What type of content is this order for? *</label>
                    </Col>
                    <Select s={12} icon={<Icon>switch_video</Icon>} id="select-video_purpose" name="video_purpose"
                            onChange={onChange} value={state.video_purpose}>
                        <option value="">{/**/}</option>
                        <option value="Youtube Content">Youtube Content</option>
                        <option value="Vertical Content For TikTok, Shorts, or ReelsAdvertising/Marketing">Vertical Content For TikTok, Shorts, or Reels</option>
                        <option value="Podcast">Podcast</option>
                        <option value="Video Advertisement">Video Advertisement</option>
                        <option value="Other">Other</option>
                    </Select>

                    <Col s={12} className="label-column" key="voice-col">
                        <label>[Optional] Add voice notes:&nbsp;&nbsp;&nbsp;</label>
                    </Col>
                    <div className='voiceRecord'>
                        <VoiceRecorder s={12} key="audio-recorder" onAudioRecorded={setVoiceBlob} audioUrl={state.voice_note}/>
                        {state.card_title && state.dropbox_link && state.video_purpose && (
                            (state.youtube_aspect_ratio !== 'Other' || otherAspectRatio) && (
                                <Button type="primary" className={styles.nextBtn} onClick={() => hideComponent('div1')}>Next</Button>
                            )
                        )}
                    </div>
             
                </div>
                <div id="div2" className={styles.fieldsWrapper} style={{ display: 'none'}}>
                    <Col s={12} className="label-column">
                        <label>Anything else we should know about this video?</label>
                    </Col>

                    <Col s={12} className="label-column labele_title">
                    <Icon className={styles.mobileMode}>short_text</Icon>
                        <Editor 
                            toolbar = {{options: ['inline', 'blockType', 'fontSize', 'list', 'colorPicker', 'emoji']}}
                            editorState={state.additional_info1}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e)=>{handleEditorStateChange(e,'additional_info')}}
                        />
                        <div className="clearfix"></div>
                    </Col>
{/* 
                    <Col s={12} className="label-column margin-bottom">
                        <label>Are there any special instructions for this video that are different than what we normally edit for you?</label>
                    </Col>
                    <Col s={12} className="label-column labele_title">
                        <Icon className={styles.mobileMode}>short_text</Icon>
                        <Editor
                            toolbar = {{options: ['inline', 'blockType', 'fontSize', 'list', 'colorPicker','emoji']}}
                            editorState={state.special_instructions1}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e)=>{handleEditorStateChange(e,'special_instructions')}}
                        />
                        <div className="clearfix"></div>
                    </Col> */}

                    {
                        (isAdmin(loggedInUser) || loggedInUser.has_youtube_helper) ?
                            <Col className="margin-bottom" s={12}><Icon className={styles.mobileMode} left>live_help</Icon>
                                <Checkbox name="youtube_helper" id="check-youtube-helper" label="Need youtube helper?" onChange={onChange} value="1" checked={state.youtube_helper}/>
                            </Col>
                            : null
                    }
                    {
                        state.youtube_helper ? React.Children.toArray([
                            <Col s={12} className="label-column margin-bottom">
                                <label>Instructions about thumbnail</label>
                            </Col>,
                            <Col s={12} className="label-column labele_title">
                                <Icon className={styles.mobileMode}>short_text</Icon>
                                <Editor
                                    toolbar = {{options: ['inline', 'blockType', 'fontSize', 'list', 'colorPicker', 'emoji']}}
                                    editorState={state.video_thumb_desc1}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={(e)=>{handleEditorStateChange(e,'video_thumb_desc')}}
                                />
                                <div className="clearfix"></div>
                            </Col>,

                            // <Col s={12} className="label-column margin-bottom">
                            //     <label>Video Thumbnail Reference Links</label>
                            // </Col>,
                            // <TextInput className="add_Btn" s={12}
                            //         icon={<MaterialButton style={{marginRight: "10px"}} className="btn-primary"
                            //                         onClick={addThumbnailLink}><Icon>add</Icon></MaterialButton>}
                            //         name="video_thumb_ref" type="url" validate placeholder="Video Thumbnail Reference Links"
                            //         onChange={e => onChange(e, 0)} value={state.video_thumb_ref[0]}/>,
                            // ...makeLinks("video_thumb_ref", state.video_thumb_ref, removeThumbnailLink),

                            <Col s={12} className="label-column">
                                <label>Instruction for video title and description</label>
                            </Col>,

                            <Col s={12} className="label-column labele_title">
                                <Icon className={styles.mobileMode}>short_text</Icon>
                                <Editor
                                    toolbar = {{options: ['inline', 'blockType', 'fontSize', 'list', 'colorPicker', 'emoji']}}
                                    editorState={state.video_optimization_desc1}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={(e)=>{handleEditorStateChange(e,'video_optimization_desc')}}
                                />
                                <div className="clearfix"></div>
                            </Col>,

                            // <Col s={12} className="label-column margin-bottom">
                            //     <label>Affiliate or Product Links</label>
                            // </Col>,
                            // <TextInput className="add_Btn" s={12}
                            //         icon={<MaterialButton style={{marginRight: "10px"}} className="btn-primary"
                            //                         onClick={addAffiliateLink}><Icon>add</Icon></MaterialButton>}
                            //         name="affiliate_links" type="url" validate placeholder="Affiliate or Product Links"
                            //         onChange={e => onChange(e, 0)} value={state.affiliate_links[0]}/>,
                            // ...makeLinks("affiliate_links", state.affiliate_links, removeAffiliateLink),
                        ]) : null
                    }
                    {
                        (isAdmin(loggedInUser) || loggedInUser.has_subtitles) ?
                            <Col s={12}><Icon className={styles.mobileMode} left>subtitles</Icon><Checkbox name="subtitles" id="check-subtitles" label="Add subtitles?" onChange={onChange} value="1" checked={state.subtitles}/></Col>
                            : null
                    }
                    <Col s={12}><Icon className={styles.mobileMode} left>all_inclusive</Icon>
                        <Checkbox id="check-repurpose" label="Do you want us to repurpose this video? " name="repurpose"
                                onChange={() => setRepurpose(prev => !prev)} value="1" checked={repurpose}/>
                    </Col>
                    {
                        repurpose ? React.Children.toArray([
                            <Col s={12} className="label-column">
                                <label>Which size(s) do you want your video in? (Max 2) *</label>
                            </Col>,
                            <Col s={12} className="label-column">
                                <Checkbox label="Vertical (9:16)" name="9-16" onChange={() => onChangeRepurposeAspect(repurpose916, setRepurpose916)}
                                        value="Vertical (9:16)" checked={repurpose916} id="9-16" disabled={!repurpose916 && repurposeRatios >= 2}/>
                            </Col>,
                            <Col s={12} className="label-column">
                                <Checkbox label="Square (1:1)" name="1-1" onChange={() => onChangeRepurposeAspect(repurposeSq, setRepurposeSq)}
                                        value="Square (1:1)" checked={repurposeSq} id="1-1" disabled={!repurposeSq && repurposeRatios >= 2}/>
                            </Col>,
                            <Col s={12} className="label-column">
                                <Checkbox label="In Feed (4:5)" name="4-5" onChange={() => onChangeRepurposeAspect(repurpose45, setRepurpose45)}
                                        value="In Feed (4:5)" checked={repurpose45} id="4-5" disabled={!repurpose45 && repurposeRatios >= 2}/>
                             </Col>,
                            <Col s={12} className="label-column">
                                <Checkbox label="Other" name="other" onChange={() => onChangeRepurposeAspect(repurposeOther, setRepurposeOther)}
                                        value="Enter Custom size" checked={repurposeOther} id="other" disabled={!repurposeOther && repurposeRatios >= 2}/>
                            </Col>,
                            repurposeOther ?
                            <TextInput s={12} placeholder="Custom video size" value={repurposeAspect} icon="aspect_ratio"
                                    onChange={e => setRepurposeAspect( e?.target?.value)}/> : null,
                            <br/>,
                            <Col s={12} className="label-column">
                                <Checkbox label="Do you want a Call To Action at the end of the repurposed video?"
                                        onChange={() => setCTARepurposeCheck(!CTARepurposeCheck)}
                                        value="1" checked={CTARepurposeCheck} id="callToAction" name="callToAction" />
                            </Col>,
                            CTARepurposeCheck ?
                                <Col s={12} className="label-column cta_label">
                                    <label>What should the CTA ask the viewer to do?</label>
                                </Col> : null,
                            CTARepurposeCheck ?
                                <Col s={12} className="label-column txt_cta">
                                    <TextInput icon={<Icon>short_text</Icon>} name="call_to_action" placeholder="Subscribe to my channel" onChange={onChange} value={state.call_to_action}/>
                                </Col> : null,<br/>,
                            <Col s={12} className="label-column">
                                <label>Do you have any other instructions for us about the repurposed edits?</label>
                            </Col>,
                            <Textarea id="otherInfo" s={12} icon={<Icon>short_text</Icon>} name="repurpose_additional_info"
                                    onChange={onChange} value={state.repurpose_additional_info}/>,
                        ]) : null
                    }
                    <div className={styles.btnWrapper}>
                        <Button type="primary" className={styles.backBtn} onClick={() => hideComponent('div2')}>Back</Button>
                        <Button type="primary" className={styles.createBtn} disabled={!validateState()} onClick={() => createCard()}>Create</Button>
                    </div>
                </div>
            </div>

        </Layout>
    );
}

export default NewVideoRequest;
