import React, {useCallback, useEffect, useState} from "react";
import {Button, Card, Col, Container, Icon, Pagination, Row} from "react-materialize";
import axios from "axios";
import {axiosClient} from "../api/httpClient";
import {connect, useSelector} from "react-redux";
import {errorToast, infoToast, isAdmin, isCustomer} from "../api/Util";
import {moveCard} from "../api/CardActions";
import {ARCHIVED, CUSTOMER, DONE} from "../api/Constants";
import TopHeader from "./TopHeader";
import _ from "lodash";
import { Input } from "antd";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import CustomDropdown from "./common/CustomDropdown/DropDown";

const cancelTokenSource = axios.CancelToken.source();

export default connect(mapStateToProps)((props) => {

    const loggedInUser = props?.auth?.loggedInUser || {};
    const customer = isCustomer(loggedInUser);
    const admin = isAdmin(loggedInUser);
    const [cards, setCards] = useState([]);
    const [filteredCards, setFilteredCards] = useState([]);
    const [index, setIndex] = useState(1);
    const [viewFor, setViewFor] = useState({ value: "0", label: 'All' });
    const [loading, setLoading] = useState(false);
    const [searchTitle, setsearchTitle] = useState("");
    const limit = 10;
    const [options, setOptions] = useState ([]);
    const websiteUsers = useSelector(state => state.allWebsiteUsers.website_users);
    const refresh = () => {
        if(loading) {
            return;
        }
        setLoading(true);
        infoToast("Loading");
        axiosClient.get('/api/cards/archive', {
            cancelToken: cancelTokenSource.token,
            params: {
                client_id: viewFor
            }
        })
            .then(({data}) => {
                setCards(data);
                setFilteredCards(data);
            })
            .catch(err => {
                if (!axios.isCancel(err)) {
                    errorToast("Something went wrong");
                    console.error(err);
                }
            }).finally(() => setLoading(false));
    };

useEffect(() => {
    const options = [{ value: "0", label: 'All' }].concat(
        (websiteUsers || [])
            .filter(user => user.client_type === CUSTOMER)
            .map(user => ({
                value: `${user.client_id}`,
                label: `${user.fullname} (${user.client_type})`, 
                className: user?.is_deleted ? 'deleted-user' : ''   
            }))
    );
    
    setOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [websiteUsers]);

    useEffect(() => {
        const filtered = cards.filter(card => 
            card.card_title.toLowerCase().includes(searchTitle.toLowerCase())
        );
        setFilteredCards(filtered);
        setIndex(1); 
    }, [searchTitle, cards]);

    

    useEffect(() => {
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewFor]);

    function unArchive(cardId) {
        infoToast("Please wait");
        moveCard(cardId, DONE, ARCHIVED);
    }

    const debouncedSearch = useCallback(_.debounce((newsearchTitle) => {
        setsearchTitle(newsearchTitle);
    }, 1000), []);

    const handleSearchChange = (event) => {
        debouncedSearch(event.target.value);
    };
    

    return (
        <Container className="archived-main">
            <div className="aside-right-top-bar">
                <div className="top-bar-left">
                    <div className="top_bar">
                        <h3>Archived</h3>
                    </div>
                </div>
                <div className="top-bar-right">
                    <TopHeader/>
                </div>
            </div>
            <Card className="archive-filter-section"
                title={<Row>
                    {
                        !customer ?
                            <Col s={4} m={3} className="archived-cus-select archived-cus-select-input">
                                <CustomDropdown
                                    options={options}
                                    placeholder="select-customer-archive"
                                    className="customer-archive-dropdown"
                                    dropdownValue={viewFor}
                                    handleOnchange={value => setViewFor(value)}
                                />
                            </Col> : null
                    }
                    <Col s={4} m={3} className="archived-cus-select">
                        <Input
                            placeholder="Search by name"
                            allowClear 
                            className="searchedUser"
                            style={{height:"49px" }}
                            onChange={handleSearchChange}
                        />
                    </Col> 
                    <Col s={4} m={3} className="archived-cus-select" style={{'float':'right'}}>
                        <button onClick={refresh} node="button" waves="light" large flat className="archived-cus-button standard-btn">
                            <Icon right>refresh</Icon>Reload 
                        </button>
                    </Col>
                </Row>} >
            </Card>
            <Card className="archived-card-table-view"> 
                <div className="archived-table-row">
                <table>
                    <thead>
                    <tr>
                        <th width="15%">Customer</th>
                        <th width="35%">Card Title</th>
                        <th width="5%">Rating</th>
                        <th width="7%">Subscription</th>
                        <th width="10%">Created On</th>
                        <th width="10%">Assigned On</th>
                        <th width="10%">Done On</th>
                        <th width="10%">Archived On</th>
                        <th width="15%">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        React.Children.toArray(filteredCards.slice((index - 1) * limit, index * limit).map(card => 
                        <tr >
                            <td>{card.client_name}</td>
                            <td><Link style={{color:"#039be5"}} target="_blank" to={`/card-details/${card.card_id}`}>{card.card_title}</Link></td>
                            <td>{card.rating || "-"}</td>
                            <td>{card.subscription_type}</td>
                            <td>{new Date(card.creation_time)?.toLocaleDateString('en-CA')}</td>
                            <td>{card.assigned_time}</td>
                            <td>{card.done_time}</td>
                            <td>{new Date(card.archived_time)?.toLocaleDateString('en-CA')}</td>
                            <td>
                                {!customer &&
                                    <Button flat icon={<Icon>unarchive</Icon>} small tooltip={admin ? "Un-Archive" : "Not Allowed"} disabled={!admin}
                                            onClick={() => unArchive(card.card_id)}/>
                                }
                            </td>
                        </tr>))
                    }

                    </tbody>
                </table>
                </div>
                
                <Row className="center-align">
                    {
                        filteredCards.length > 0 ?
                            <Row className="center-align">
                                <Pagination
                                    activePage={index}
                                    items={Math.ceil(filteredCards.length / limit)}
                                    leftBtn={<Icon>chevron_left</Icon>}
                                    rightBtn={<Icon>chevron_right</Icon>}
                                    onSelect={i => setIndex(i)}
                                />
                            </Row> : null
                    }
                </Row>
            </Card>
        </Container>
    );
});

function mapStateToProps({auth, users}) {
    return {auth, users};
}
