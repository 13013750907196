import React, { useEffect, useState } from "react";
import { Layout, Card, Typography } from "antd";
import styles from "./ExportCardsStyle.module.scss";
import TopHeader from "../TopHeader/TopHeader";
import { Button } from "react-materialize";
import { exportCardBasedOnFilter } from "../../services/cardsService";
import { errorToast, successToast, toISODate } from "../../api/Util";
import { CSVLink } from "react-csv";
import CustomDropdown from "../common/CustomDropdown/DropDown";
import TableView from "./TableView";
import { useSelector } from "react-redux";
import { CUSTOMER } from "../../api/Constants";
import CustomDatePicker from "../common/DatePicker";

const ExportCards = () => {
  const websiteUsers = useSelector(state => state.allWebsiteUsers.website_users);
  const [selectedLimit, setSelectedLimit] = useState(null);
  const [cardsData, setCardsData] = useState([]);
  const [viewTable, setViewTable] = useState(false); // State to control view table
  const [options, setOptions] = useState([]);
	const [viewAs, setViewAs] = useState("");
  const [startDate, setStartDate] = useState(addDays(new Date(), -29));
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    var option;
    option = (websiteUsers || [])?.filter((user) => user.client_type === CUSTOMER).map(user => {
      return {
        value: `${user.client_id}`,
        label: `${user.fullname} (${user.client_type})`,
        className: user?.is_deleted ? 'deleted-user' : ''
      }
    })
    setOptions(option);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websiteUsers]);

  function addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    if (new Date(result).getTime() < new Date('2022-12-20').getTime()) {
      result = (new Date('2022-12-20'));
    }
    return result;
  }

  // Handle limit dropdown change
  const handleLimitChange = (value) => {
    setSelectedLimit(value);
  };

  const handleCustomerTypeChange = (value) => {
    setViewAs(value);
  };

  // Handle View & Export button click
  const handleViewClick = async () => {
    const requestData = {
      startDate: toISODate(startDate),
      endDate: toISODate(endDate),
      limit: selectedLimit,
      customerId: viewAs,
    };
    try {
      const response = await exportCardBasedOnFilter(requestData);
      if (response.status) {
        const filteredData = response.data?.map((item) => ({
          "Card Title": item.card_title,
          "Card Status": item.card_status,
          "Qa Name": item.qaFirstName + " " + item.qaLastName,
          "Customer Name": item.customerFirstName + " " + item.customerLastName,
          "created Date": item.creation_time,
        }));
        setCardsData(filteredData);
        setSelectedLimit(null);
        successToast("Data loaded successfully");
        setViewTable(true);
      } else {
        errorToast("Something went wrong");
      }
    } catch (error) {
      console.error(error);
      errorToast("Data loading failed");
    }
  };

  return (
    <Layout className={styles.homeLayout}>
      <Card className={styles.topHeader} loading={false}>
        <TopHeader />
      </Card>
      <div className={styles.container}>
        <div className={styles.cardsTitleDiv}>
          <Typography.Title className={styles.cardsTitle}>
            Export Card
          </Typography.Title>
        </div>
        <div className={styles.filtersAndTotalCount}>
          <div className={styles.selectBoxWrapper}>
            <CustomDatePicker className={`${styles.viewAsStyle} ${styles.mobileMode}`}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              startDate={startDate}
              endDate={endDate}
            />
            <CustomDropdown
              options={Array.from({ length: 10 }, (_, index) => ({
                value: 100 + index * 100,
                label: `${100 + index * 100}`,
              }))}
              placeholder={"Limit"}
              className={styles.viewAsStyle}
              dropdownValue={selectedLimit}
              handleOnchange={handleLimitChange}
            />
            <CustomDropdown
              options={options}
              placeholder="Select Customer Profile"
              className="z-depth-1 select-view-as upload-customer-files"
              dropdownValue={viewAs}
              handleOnchange={handleCustomerTypeChange}
            />
            
            {/* Export as CSV Button */}
            <Button
              className={`standard-btn ${styles.exportButton}`}
              onClick={handleViewClick} // Now only for viewing
            >
              View
            </Button>

            <Button
              className={`standard-btn ${styles.exportButton}`}
              onClick={() => {
                const csvLink = document.getElementById("csv-download-link");
                if (csvLink) {
                  csvLink.click();
                }
              }}
            >
              Export
            </Button>
            <div style={{ display: "none" }}>
              <CSVLink
                data={cardsData}
                filename={`exported_data_${startDate}_${endDate}.csv`}
                target="_blank"
                id="csv-download-link"
              >
                Export As Csv
              </CSVLink>
            </div>
          </div>
        </div>

        {/* Conditionally render the TableView */}
        {viewTable && <TableView data={cardsData} />}
      </div>
    </Layout>
  );
};

export default ExportCards;
